.control-panel-main {
  font-weight: 300;

  img {
    max-width: 100%;
    height: auto;
  }

  p {
    max-width: 960px;
  }
}

// https://design.volvocars.com/type/principles/

h1 {
  font-size: 2.5em;
  font-weight: 500;
}

h2 {
  font-size: 1.5em;
  font-weight: normal;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
}

h3, h4, h5, h6 {
  font-weight: normal;
  margin-top: 1.75em;
  margin-bottom: 0.5em;
}

.revoked.MuiDataGrid-row,
.obsolete.MuiDataGrid-row {
  .MuiDataGrid-cellContent {
    text-decoration: line-through;
    color: #939393;
  }
}

.CodeMirror-lint-tooltip {
  /* Make sure that it is in front of the dialog */
  /* Dialog has 1300 z-index and and graphiql tooltip has 100 by default */
  z-index: 1301 !important;
}

