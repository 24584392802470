.error-message {
    margin: 0 auto;
    font-size: 14px;
    pre {
        margin: 0;
    }
}

.error-message:not(:last-child) {
    margin-bottom: 10px;
}
