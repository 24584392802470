@keyframes bouncein {
  0% {transform: translateY(-100px) translateX(3px);}
  40% {transform: translateY(0) translateX(3px);}
  60% {transform: translateY(-4px) translateX(1px) rotate(-9deg);}
  100% {transform: translateY(0) rotate(-18deg);}
}

.mini-crown {
  animation: bouncein 1s ease;
  transform: translateY(0) rotate(-18deg);
}
