.graphiql-container .graphiql-editor,
.graphiql-editor .CodeMirror-info,
.graphiql-editor .CodeMirror-lint-tooltip,
.graphiql-editor reach-portal {
  /* Colors */
  --color-primary: 359, 58%, 39%;
  --color-secondary: 242, 51%, 61%;
  --color-tertiary: 188, 100%, 36%;
  --color-info: 210, 74%, 42%;
  --color-success: 158, 60%, 42%;
  --color-warning: 36, 100%, 41%;
  --color-error: 13, 93%, 58%;
  --color-neutral: 219, 28%, 32%;
  --color-base: 219, 28%, 100%;

  --editor-background: 228, 100%, 98%; /* #f5f7ff */

  /* Font */
  --font-family: 'Roboto', sans-serif;
  --font-family-mono: 'Fira Code', monospace;
  --font-size-hint: calc(12rem / 16);
  --font-size-inline-code: calc(13rem / 16);
  --font-size-body: calc(15rem / 16);
  --font-size-h4: calc(18rem / 16);
  --font-size-h3: calc(22rem / 16);
  --font-size-h2: calc(29rem / 16);
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --line-height: 1.5;

  /* Spacing */
  --px-2: 2px;
  --px-4: 4px;
  --px-6: 6px;
  --px-8: 8px;
  --px-10: 10px;
  --px-12: 12px;
  --px-16: 16px;
  --px-20: 20px;
  --px-24: 24px;

  /* Border radius */
  --border-radius-2: 2px;
  --border-radius-4: 4px;
  --border-radius-8: 8px;
  --border-radius-12: 12px;

  /* Popover styles (tooltip, dialog, etc) */
  --popover-box-shadow: 0px 6px 20px rgba(59, 76, 106, 0.13),
  0px 1.34018px 4.46726px rgba(59, 76, 106, 0.0774939),
  0px 0.399006px 1.33002px rgba(59, 76, 106, 0.0525061);
  --popover-border: none;

  /* Layout */
  --sidebar-width: 44px;
  --toolbar-width: 40px;
  --session-header-height: 51px;
}

.CodeMirror.cm-s-graphiql {
  background: #f5f7ff !important;
}

.cm-s-graphiql span.cm-comment { color: #7089AC; }
.cm-s-graphiql span.cm-atom { color: #a16a94; }
.cm-s-graphiql span.cm-number { color: #a16a94; }

.cm-s-graphiql span.cm-property { color: #284E80; }
.cm-s-graphiql span.cm-attribute { color: #476D3B; }
.cm-s-graphiql span.cm-keyword { color: #9E2A2B; }
.cm-s-graphiql span.cm-string { color: #A85F02; }

.cm-s-graphiql span.cm-variable { color: #1C6BBA; }
.cm-s-graphiql span.cm-variable-2 { color: #01a0e4; }
.cm-s-graphiql span.cm-def { color: #1B365D; }
.cm-s-graphiql span.cm-bracket { color: #3a3432; }
.cm-s-graphiql span.cm-tag { color: #9E2A2B; }
.cm-s-graphiql span.cm-link { color: #a16a94; }
.cm-s-graphiql span.cm-error { background: #BF2012; color: #5c5855; }

.cm-s-graphiql .CodeMirror-activeline-background { background: #e8f2ff; }
.cm-s-graphiql .CodeMirror-matchingbracket { color: #9E2A2B !important; }
