
.react-markdown {

  .row {
    display: flex;
    flex-wrap: wrap;

    > * {
      flex-grow: 1;
    }

    max-width: 960px;
  }

  .row-code {
    border: 1px solid #f4f4f4;
    border-left-width: 3px;

    > * {
      padding: 10px;
      border: 1px solid #f4f4f4;
    }

    p,
    pre,
    pre > div {
      margin: 0 !important;
      padding: 0 !important;
      background: none !important;
    }

    code {
      background: none !important;
    }

    pre * {
      font-size: 13px;
      line-height: 17px !important;
    }

    hr {
      border: 1px solid #f4f4f4;
    }
  }

  .mermaid .edgeLabel {
    background-color: #fff !important;
    padding: 0.05em;
  }


  /* We use these as headers for <pre> tags (code blocks) */
  h6 {
    font-size: 1em;
    background: #eee;
    margin-bottom: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 0.5em;
  }

  h6 + pre {
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    > div {
      margin-top: 0 !important;
    }
  }

  blockquote {
    background: #eaf0f6;
    margin-left: 0;
    margin-right: 0;
    padding: 0.1em 1em;
    border-left: 3px solid #7089AC;
  }

  code {
    background: #f8f9f9;
    border-radius: 3px;
    padding: 0.1em 0.2em;
  }

  pre code {
    padding: 0;
  }

  // Give the table a more traditional look
  table {
    border-collapse: collapse;
    width: 100%;
    margin: 0;
    border: 1px solid #f4f4f4;
    font-size: 0.9em;

    td, th {
      border: 1px solid #f4f4f4;
      padding: 0.5em;
      vertical-align: top;
    }

    th {
      background: #f4f4f4;
    }
  }
}
