body {
  margin: 0;
  color: #141414;
  background: #202A44;
  font-family: 'Volvo Novum 2', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code,
.font-monospace,
.font-monospace input,
.font-monospace textarea
{
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Volvo Novum 2';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('./fonts/volvo-novum-latin-2-001/VolvoNovumWebLatin-SemiLight.eot');
  src: local(''),
  url('./fonts/volvo-novum-latin-2-001/VolvoNovumWebLatin-SemiLight.eot?#iefix') format('embedded-opentype'),
  url('./fonts/volvo-novum-latin-2-001/VolvoNovumWebLatin-SemiLight.woff2') format('woff2'),
  url('./fonts/volvo-novum-latin-2-001/VolvoNovumWebLatin-SemiLight.woff') format('woff'),
  url('./fonts/volvo-novum-latin-2-001/VolvoNovumWebLatin-SemiLight.ttf') format('truetype'),
  url('./fonts/volvo-novum-latin-2-001/VolvoNovumWebLatin-SemiLight.svg#VolvoNovum') format('svg');
}

@font-face {
  font-family: 'Volvo Novum 2';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url('./fonts/volvo-novum-latin-2-001/VolvoNovumWebLatin-SemiLightItalic.eot');
  src: local(''),
  url('./fonts/volvo-novum-latin-2-001/VolvoNovumWebLatin-SemiLightItalic.eot?#iefix') format('embedded-opentype'),
  url('./fonts/volvo-novum-latin-2-001/VolvoNovumWebLatin-SemiLightItalic.woff2') format('woff2'),
  url('./fonts/volvo-novum-latin-2-001/VolvoNovumWebLatin-SemiLightItalic.woff') format('woff'),
  url('./fonts/volvo-novum-latin-2-001/VolvoNovumWebLatin-SemiLightItalic.ttf') format('truetype'),
  url('./fonts/volvo-novum-latin-2-001/VolvoNovumWebLatin-SemiLightItalic.svg#VolvoNovum') format('svg');
}

@font-face {
  font-family: 'Volvo Novum 2';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./fonts/volvo-novum-latin-2-001/VolvoNovumWebLatin-Regular.eot');
  src: local(''),
  url('./fonts/volvo-novum-latin-2-001/VolvoNovumWebLatin-Regular.eot?#iefix') format('embedded-opentype'),
  url('./fonts/volvo-novum-latin-2-001/VolvoNovumWebLatin-Regular.woff2') format('woff2'),
  url('./fonts/volvo-novum-latin-2-001/VolvoNovumWebLatin-Regular.woff') format('woff'),
  url('./fonts/volvo-novum-latin-2-001/VolvoNovumWebLatin-Regular.ttf') format('truetype'),
  url('./fonts/volvo-novum-latin-2-001/VolvoNovumWebLatin-Regular.svg#VolvoNovum') format('svg');
}

@font-face {
  font-family: 'Volvo Novum 2';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('./fonts/volvo-novum-latin-2-001/VolvoNovumWebLatin-Italic.eot');
  src: local(''),
  url('./fonts/volvo-novum-latin-2-001/VolvoNovumWebLatin-Italic.eot?#iefix') format('embedded-opentype'),
  url('./fonts/volvo-novum-latin-2-001/VolvoNovumWebLatin-Italic.woff2') format('woff2'),
  url('./fonts/volvo-novum-latin-2-001/VolvoNovumWebLatin-Italic.woff') format('woff'),
  url('./fonts/volvo-novum-latin-2-001/VolvoNovumWebLatin-Italic.ttf') format('truetype'),
  url('./fonts/volvo-novum-latin-2-001/VolvoNovumWebLatin-Italic.svg#VolvoNovum') format('svg');
}

@font-face {
  font-family: 'Volvo Novum 2';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('./fonts/volvo-novum-latin-2-001/VolvoNovumWebLatin-Medium.eot');
  src: local(''),
  url('./fonts/volvo-novum-latin-2-001/VolvoNovumWebLatin-Medium.eot?#iefix') format('embedded-opentype'),
  url('./fonts/volvo-novum-latin-2-001/VolvoNovumWebLatin-Medium.woff2') format('woff2'),
  url('./fonts/volvo-novum-latin-2-001/VolvoNovumWebLatin-Medium.woff') format('woff'),
  url('./fonts/volvo-novum-latin-2-001/VolvoNovumWebLatin-Medium.ttf') format('truetype'),
  url('./fonts/volvo-novum-latin-2-001/VolvoNovumWebLatin-Medium.svg#VolvoNovum') format('svg');
}

@font-face {
  font-family: 'Volvo Novum 2';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url('./fonts/volvo-novum-latin-2-001/VolvoNovumWebLatin-MediumItalic.eot');
  src: local(''),
  url('./fonts/volvo-novum-latin-2-001/VolvoNovumWebLatin-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('./fonts/volvo-novum-latin-2-001/VolvoNovumWebLatin-MediumItalic.woff2') format('woff2'),
  url('./fonts/volvo-novum-latin-2-001/VolvoNovumWebLatin-MediumItalic.woff') format('woff'),
  url('./fonts/volvo-novum-latin-2-001/VolvoNovumWebLatin-MediumItalic.ttf') format('truetype'),
  url('./fonts/volvo-novum-latin-2-001/VolvoNovumWebLatin-MediumItalic.svg#VolvoNovum') format('svg');
}

@font-face {
  font-family: 'Volvo Novum 2';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('./fonts/volvo-novum-latin-2-001/VolvoNovumWebLatin-Bold.eot');
  src: local(''),
  url('./fonts/volvo-novum-latin-2-001/VolvoNovumWebLatin-Bold.eot?#iefix') format('embedded-opentype'),
  url('./fonts/volvo-novum-latin-2-001/VolvoNovumWebLatin-Bold.woff2') format('woff2'),
  url('./fonts/volvo-novum-latin-2-001/VolvoNovumWebLatin-Bold.woff') format('woff'),
  url('./fonts/volvo-novum-latin-2-001/VolvoNovumWebLatin-Bold.ttf') format('truetype'),
  url('./fonts/volvo-novum-latin-2-001/VolvoNovumWebLatin-Bold.svg#VolvoNovum') format('svg');
}

@font-face {
  font-family: 'Volvo Novum 2';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('./fonts/volvo-novum-latin-2-001/VolvoNovumWebLatin-BoldItalic.svg');
  src: local(''),
  url('./fonts/volvo-novum-latin-2-001/VolvoNovumWebLatin-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('./fonts/volvo-novum-latin-2-001/VolvoNovumWebLatin-BoldItalic.woff2') format('woff2'),
  url('./fonts/volvo-novum-latin-2-001/VolvoNovumWebLatin-BoldItalic.woff') format('woff'),
  url('./fonts/volvo-novum-latin-2-001/VolvoNovumWebLatin-BoldItalic.ttf') format('truetype'),
  url('./fonts/volvo-novum-latin-2-001/VolvoNovumWebLatin-BoldItalic.svg#VolvoNovum') format('svg');
}
