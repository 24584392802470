
._11RoI {
  line-height: 1.4;
}

._3QHg2, ._17H2C {
  margin-left: -1em;
  opacity: 0.2;
  width: 1em;
  margin-right: 0;
  display: inline-block;
}

._11RoI {
  background-color: transparent !important;
}
